import { FullGameData } from '@/lib/drupal/models/Games';
import { GenreBreadcrumb, useGenreBreadcrumbs } from '@/lib/hooks/useGameBreadcrumbs';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { If } from 'react-commons';

interface LDAggregateProps {
  '@type': string;
  name: string;
  ratingValue: string;
  ratingCount: number;
  bestRating: number;
  worstRating: number;
}

interface LDOffersProps {
  '@type': string;
  availability: string;
  price: string;
  priceCurrency: 'USD' | 'CAD';
}

interface LDScreenshotProps {
  '@type': string;
  thumbnailUrl: string;
}

interface LDCreatorProps {
  '@context': string;
  '@type': string;
  '@id'?: string;
  name: string;
  url?: string;
  alternateName?: string[];
  sameAs?: string[];
  parentOrganization?: any
}

interface LDItemListProps {
  '@type': string;
  position: number;
  name?: string;
  item: string | {
    '@id': string;
    name: string;
    item?: string;
  };
}

interface LDPotentialActionProps {
  '@type': string;
  name: string;
  target: string;
  'query-input': string;
}

interface LDMainEntityProps {
  '@type': string;
  name: string;
  acceptedAnswer?: {
    '@type': string;
    text: string;
  }
}

interface LDContactPoint {
  '@type': 'ContactPoint',
  contactType: string;
  url: string;
  email: string;
}

interface LDProps {
  '@context': string;
  '@type': string | string[];
  '@id': string;
  name: string;
  description: string;
  image: string;
  logo: string;
  url: string;
  breadcrumb: Partial<LDProps>
  applicationCategory: string;
  applicationSubCategory: string;
  operatingSystem: string;
  sameAs: string[];
  contactPoint: LDContactPoint[];
  inLanguage: string[];
  mainEntity: LDMainEntityProps[];
  itemListElement: LDItemListProps[];
  aggregateRating: Partial<LDAggregateProps>;
  potentialAction: Partial<LDPotentialActionProps>;
  offers: Partial<LDOffersProps>;
  screenshot: Partial<LDScreenshotProps>;
  publisher: Partial<LDCreatorProps>;
  genre: string[];
  gamePlatform: string[];
  creator: Partial<LDCreatorProps>;
  email: string;
}

interface LDGame {
  title
  thumbnailUrl
  rating
  totalVotes
  path
  genre: {
    name
    path
  }
  descriptionSimple
  developer
  supportsMobile
  instructions
  published
}

export interface MetaProps {
  title: string
  titleTag: string
  name?: string
  description?: string
  keywords?: string
  image?: string
  itemDescription?: string
  profile?: string
  profilePicture?: string
  itemImage?: string
  thumbnail?: string
  openGraph?: Partial<{
    site_name: string
    title: string
    type: string
    url: string
    image: string
    video: string
    locale: string
    region: string
    country_name: string
    updated_time: string
    'person:username': string
    'profile:username': string
    'article:published_time': string
    'article:modified_time': string
    'image:width': string
    'image:height': string
    'image:type': string
    description?: string
  }>
  twitter?: Partial<{
    title: string
    description: string
    image: string
    url: string
    card: string
  }>
  ldjson?: Partial<LDProps>[]
  sitemapProps?: {
    priority?: '0.5' | '1.0'
    changefreq?: 'never' | 'daily' | 'weekly' | 'monthly' | 'yearly'
    excludeFromSitemap?: boolean
  }
  noIndex?: boolean
}

export const generateTaxonomyBreadcrumbListLDJSON = (name, image, description, path, data: { url: string, name: string }[]): Partial<LDProps>[] => {
  return [
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: `${name} Games`, 
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: process.env.APP_CONFIG.DEFAULT_TITLE,
            item: process.env.APP_CONFIG.URL
          },
  
          ...data.map((breadcrumb, index) => ({
            '@type': 'ListItem',
            position: index + 2,
            name: breadcrumb.name.trim(),
            item: process.env.APP_CONFIG.URL + breadcrumb.url
          }))
        ]
      },
      image: `${process.env.APP_CONFIG.URL}${image}`,
      description: (description || '').trim(),
      publisher: {
        '@type': 'Organization',
        '@id': 'https://www.shockwave.com',
        name: 'Shockwave.com',
        url: 'https://www.shockwave.com'
      },
      url: `${process.env.APP_CONFIG.URL}${path}`
    },
  ];
};

export const generateGamePageLDJSON = (game: FullGameData, breadcrumbs: GenreBreadcrumb[]): Partial<LDProps>[] => {
  const gameGenre = breadcrumbs[ 0 ];

  return [
    {
      '@context':'https://schema.org/',
      '@type': 'WebApplication',
      name: String(game?.title || '').trim(),
      image: game.thumbnailImgXl,
      aggregateRating:{
        '@type':'AggregateRating',
        ratingValue: game.rating,
        ratingCount: game.numRatings,
        bestRating: 5,
        worstRating: 1,
      },
      applicationCategory: 'Game',
      applicationSubCategory: gameGenre.text,
      description: game.shortDescription,
      'offers': {
        '@type': 'Offer',
        'availability': 'http://schema.org/InStock',
        'price': '0',
        'priceCurrency': 'USD'
      },
      creator: {
        '@type':'Person',
        name: game.credits
      },
      operatingSystem: !game.isDownloadGame ? 'Windows, Linux, MacOS, iOS, Android, any' : 'Windows',
      'screenshot':{
        '@type':'ImageObject',
        thumbnailUrl: game.thumbnailImgXl
      }
    },
    {
      '@context':'http://schema.org',
      '@type':'BreadcrumbList',
      itemListElement: [
        {
          '@type':'ListItem',
          position:1,
          item:{
            '@id': process.env.APP_CONFIG.URL,
            name:'Games'
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': process.env.APP_CONFIG.URL + gameGenre.href,
            name: gameGenre.text
          }
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@id': process.env.APP_CONFIG.URL + game.href,
            name: game.title
          }
        }
      ]
    },
    {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type':'Question',
          name:`How do you play ${String(game?.title || '').trim()}?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: String(game?.instructions || '').trim() || ''
          }
        },
        {
          '@type':'Question',
          name: `Who developed ${game?.title || ''}?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: game.credits
          }
        },
        {
          '@type':'Question',
          name:`When was ${String(game?.title || '')} released?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: `Released date: ${(game.publishedAt || '')}`
          }
        },
        {
          '@type':'Question',
          name:`Are there any tips for ${String(game?.title || '')}?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: game.instructions
          }
        }
      ]
    }
  ];
};

export const generateGamePageMetaData = (game: FullGameData, breadcrumbs: GenreBreadcrumb[]): Partial<MetaData> => {
  const thumbnailParts = game.thumbnailImgXl.split('.');
  const thumbnailExt = thumbnailParts[ thumbnailParts.length - 1 ];
  const thumbnailMimeType = thumbnailExt === 'png'
    ? 'image/png'
    : 'image/jpeg';

  const title = `${game.title} - Free Online Games and Free ${breadcrumbs[ 0 ].text} Games from Shockwave.com`;

  return {
    title,
    titleTag: title,
    description: game.shortDescription,
    image: game.thumbnailImgXl,
    thumbnail: game.thumbnailImgXl,
    itemImage: game.thumbnailImgXl,
    keywords: `${game.title}, ${breadcrumbs[ 0 ].text}`,
    openGraph: {
      url: process.env.APP_CONFIG.URL + game.href,
      updated_time: (game.lastUpdated || '').toString(),
      country_name: 'United States',
      region: 'United States',
      locale: 'en_US',
      'article:published_time': (game.publishedAt || '').toString(),
      'article:modified_time': (game.lastUpdated || '').toString(),
      'image:width': '600',
      'image:height': '320',
      'image:type': thumbnailMimeType,
    },
    twitter: {
      title: game.title.trim(),
      url: process.env.APP_CONFIG.URL + game.href
    },
    ldjson: [ ...generateGamePageLDJSON(game, breadcrumbs) ],
  };
};

export interface MetaData extends MetaProps {}

const Meta = (props: Partial<MetaProps>) => {

  const router = useRouter();

  /*
    The idea here is to use the props to generate the core of the meta tags
    and then if a prop is non-existant, we will have a default fallback.

    This doesn't apply to LD+JSON which is a bit more complex
  */

  const json: MetaProps = {
    title: props.titleTag,
    name: props.name || props.title || process.env.APP_CONFIG.DEFAULT_TITLE || null,
    description: props.description || process.env.APP_CONFIG.DESCRIPTION || null,
    keywords: props.keywords || process.env.APP_CONFIG.KEYWORDS || null,
    itemDescription: props?.itemDescription || null,
    itemImage: props?.itemImage || null,
    thumbnail: props?.thumbnail || null,
    profile: props?.profile || null,
    profilePicture: props?.profilePicture || null,
    openGraph: {
      site_name: process.env.APP_CONFIG.APP_TITLE || null,
      description: props.openGraph?.description || null,
      title: props.title || process.env.APP_CONFIG.DEFAULT_TITLE || null,
      type: props.openGraph?.type! || 'game' || null,
      url: props.openGraph?.url || process.env.APP_CONFIG.URL || null,
      region: props.openGraph?.region || 'United States',
      locale: props.openGraph?.locale || 'en_US',
      country_name: props.openGraph?.country_name || 'United States',
      updated_time: props.openGraph?.updated_time || null,
      image: props.image || null,
      'person:username': props.openGraph?.[ 'person:username' ] || null,
      'article:published_time': props.openGraph?.[ 'article:published_time' ] || null,
      'article:modified_time': props.openGraph?.[ 'article:modified_time' ] || null,
      'image:width': props.openGraph?.[ 'image:width' ] || null,
      'image:height': props.openGraph?.[ 'image:height' ] || null,
      'image:type': props.openGraph?.[ 'image:type' ] || null,
    },
    twitter: {
      title:  props.twitter?.title || props.title || process.env.APP_CONFIG.DEFAULT_TITLE || null,
      description: props.twitter?.description || props.description || process.env.APP_CONFIG.DESCRIPTION || null,
      url: props.twitter?.url || process.env.APP_CONFIG.URL || null,
      image: props.image || null,
      card: props.twitter?.card! || 'summary' || null,
    },
    ldjson: [ ...(props?.ldjson || []) ],
    titleTag: '',
  };
  
  return (
    <Head>
      {/* Configuration */}
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover' />
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta httpEquiv='content-language' content='en' />
      <meta httpEquiv='x-ua-compatible' content='IE=edge' />
      <meta httpEquiv='audience' content='General' />

      {/** meta tags */}
      <meta name='description' content={process.env.APP_CONFIG.DESCRIPTION} key='description' />
      <meta name='abstract' content={process.env.APP_CONFIG.DESCRIPTION} key='abstract' />
      <meta name='keywords' content={process.env.APP_CONFIG.KEYWORDS} key='keywords'/>
      <link rel='canonical' href={`${process.env.APP_CONFIG.URL}${router.asPath}`} key='canonical' />
      <link rel='shortlink' href={`${process.env.APP_CONFIG.URL}${router.asPath}`} key='shortlink' />
      <meta property='og:type' content='game' key='og-type'/>
      <meta property='og:site_name' content={process.env.APP_CONFIG.APP_TITLE} key='og-sitename' />
      <meta property='og:url' content={`${process.env.APP_CONFIG.URL}${router.asPath}`} key='og-url'/>
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:url' content={`${process.env.APP_CONFIG.URL}${router.asPath}`} />
      {
        If(
          process.env.APP_CONFIG.URL.includes('new') || 
          process.env.APP_CONFIG.URL.includes('local') || 
          props.noIndex, 
          () => (
            <meta name='robots' content='noindex' />
          )).EndIf()
      }

      {/* Favicons */}
      <link href='/images/app/favicon-16x16.png' rel='icon' type='image/png' sizes='16x16' />
      <link href='/images/app/favicon-32x32.png' rel='icon' type='image/png' sizes='32x32' />

      {/* Orientation  */}
      <meta name='screen-orientation' content='portrait' />

      {/* Android */}
      <meta name='theme-color' content='#FFFFFF' media='(prefers-color-scheme: light)' />
      <meta name='theme-color' content='#FFFFFF' media='(prefers-color-scheme: dark)' />
      <meta name='mobile-web-app-capable' content='yes' />
      <link href='/images/app/android-chrome-192x192.png' rel='icon' sizes='192x192' />
      <link href='/images/app/android-chrome-512x512.png' rel='icon' sizes='512x512' />

      {/* iOS */}
      <meta name='apple-mobile-web-app-title' content={process.env.APP_CONFIG.DEFAULT_TITLE} />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <link href='/images/app/apple-touch-icon.png' rel='apple-touch-icon' />
      {/* <link href='/images/app/splash-1024.png' rel='apple-touch-startup-image' /> */}

      {/* Facebook */}
      <meta property='fb:admins' content='1474163203' />
      <meta property='fb:admins' content='690197317' />
      <meta property='fb:admins' content='671820682' />
      <meta property='fb:admins' content='29900452' />
      <meta property='fb:admins' content='100001282501617' />
      <meta property='fb:admins' content='611128152' />
      <meta property='fb:admins' content='100002337691955' />
      <meta property='fb:admins' content='100002295361980' />
      <meta property='fb:admins' content='eniac.tube' />
      <meta property='fb:app_id' content='187708801677' />

      {/* Overrides */}
      {If(json.title, () => <title>{json.title.trim()}</title>).EndIf()}
      {If(json.name, () => <meta itemProp='name' content={json.name.trim()} />).EndIf()}
      {If(json.itemDescription, () => <meta itemProp='description' content={json.itemDescription.trim()} />).EndIf()}
      {If(json.itemImage, () => <meta itemProp='image' content={json.itemImage} />).EndIf()}
      {If(json.thumbnail, () => <meta name='thumbnail' content={json.thumbnail} />).EndIf()}
      {If(json.description, () => <meta name='description' content={json.description.trim()} key='description'/>).EndIf()}
      {If(json.keywords, () => <meta name='keywords' content={json.keywords.trim()} key='keywords'/>).EndIf()}
      {If(json.openGraph.site_name, () => <meta property='og:site_name' content={json.openGraph.site_name.trim()} />).EndIf()}
      {If(json.openGraph.title, () => <meta property='og:title' content={json.openGraph.title.trim()} />).EndIf()}
      {If(json.openGraph.type, () => <meta property='og:type' content={json.openGraph.type.trim()} key='og-type'/>).EndIf()}
      {If(json.openGraph.url, () => <meta property='og:url' content={json.openGraph.url.trim()} key='og-url'/>).EndIf()}
      {If(json.openGraph.image, () => <meta property='og:image' content={json.openGraph.image} />).EndIf()}
      {If(json.openGraph.description, () => <meta property='og:description' content={json.openGraph.description.trim()} />).EndIf()}
      {If(json.openGraph.image, () => <meta property='og:image:url' content={json.openGraph.image} />).EndIf()}
      {If(json.openGraph.image, () => <meta property='og:image:secure_url' content={json.openGraph.image} />).EndIf()}
      {If(json.openGraph.country_name, () => <meta property='og:country_name' content={json.openGraph.country_name.trim()} />).EndIf()}
      {If(json.openGraph.region, () => <meta property='og:region' content={json.openGraph.region.trim()} />).EndIf()}
      {If(json.openGraph.locale, () => <meta property='og:locale' content={json.openGraph.locale.trim()} />).EndIf()}
      {If(json.openGraph.updated_time, () => <meta property='og:updated_time' content={json.openGraph.updated_time.trim()} />).EndIf()}
      {/* {If(json.profilePicture, () => <link property='image_src' href={json.profilePicture.trim()} />).EndIf()} */}
      {If(json.profilePicture, () => <meta name='twitter:image' content={json.profilePicture.trim()} />).EndIf()}
      {If(json.profilePicture, () => <meta property='og:image' content={json.profilePicture.trim()} key='og-image' />).EndIf()}
      {If(json.profile, () => <meta property='profile:username' content={json.profile.trim()} />).EndIf()}
      {If(json.openGraph[ 'profile:username' ], () => <meta property='profile:username' content={json.openGraph[ 'profile:username' ].trim()} />).EndIf()}

      {If(json.openGraph[ 'article:modified_time' ], () => <meta property='article:modified_time' content={json.openGraph[ 'article:modified_time' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'article:published_time' ], () => <meta property='article:published_time' content={json.openGraph[ 'article:published_time' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'image:width' ], () => <meta property='og:image:width' content={json.openGraph[ 'image:width' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'image:height' ], () => <meta property='og:image:height' content={json.openGraph[ 'image:height' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'image:type' ], () => <meta property='og:image:type' content={json.openGraph[ 'image:type' ].trim()} />).EndIf()}
      {If(json.twitter.title, () => <meta name='twitter:title' content={json.twitter.title.trim()} />).EndIf()}
      {If(json.twitter.description, () => <meta name='twitter:description' content={json.twitter.description.trim()} />).EndIf()}
      {If(json.twitter.card, () => <meta name='twitter:card' content={json.twitter.card.trim()} />).EndIf()}
      {If(json.twitter.image, () => <meta name='twitter:image' content={json.twitter.image} />).EndIf()}

      {/* LD JSON */}
      {
        <script 
          type='application/ld+json' 
          dangerouslySetInnerHTML={{ 
            __html: `[ ${json.ldjson.map(ld => JSON.stringify(ld, null, 2) )} ]` //JSON.stringify(, null, 2) 
          }} 
        />  
      }
    </Head>
  );
};

export default Meta;
